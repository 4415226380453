import React from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Overview from "./Overview"

export default props => {
  const [show, setShow] = React.useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <Button onClick={handleShow} className="shadow">
        All sensors
      </Button>
      <Modal
        className="my-modal text-light"
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        animation={true}
        dialogClassName="rounded"
        scrollable
      >
        <Modal.Body align="center">
          <Overview data={props.data} onClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}
