import React from "react"
import Div100vh from "react-div-100vh"

export default () => {
  return (
    <Div100vh className="w-100 h-100 d-flex justify-content-center align-items-center position-absolute text-danger">
      <div
        className="py-3 px-4 rounded shadow"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        {`No data available`}
      </div>
    </Div100vh>
  )
}
