import React, { useState, useContext } from "react"
import ReactMapGL from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import Pin from "./modules/Pin"
import Navigation from "./navigation/Navigation"
import Error from "./modules/Error"
import { DataContext } from "./modules/DataContext"

const settings = {
  // dragPan: false,
  // dragRotate: false,
  // touchRotate: false,
  // scrollZoom: false,
  // touchZoom: false,
  // keyboard: false,
  // doubleClickZoom: false,
  minZoom: 4,
  maxZoom: 18,
  minPitch: 0,
  maxPitch: 50,
}

export default () => {
  const [dataInRange] = useContext(DataContext)

  const position = dataInRange.config.map || {};

  const [viewport, setViewport] = useState({
    latitude: position.latitude || 59.9139,
    longitude: position.longitude || 10.7522,
    pitch: position.pitch || 50,
    bearing: position.bearing || 0,
    zoom: position.zoom || 12,
  })

  return (
    <ReactMapGL
      {...settings}
      {...viewport}
      onViewportChange={(viewport) => {
        setViewport(viewport)
      }}
      mapboxApiAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
      mapStyle="mapbox://styles/mapbox/dark-v9"
      // mapStyle="mapbox://styles/mapbox/streets-v11"
      width="100%"
      height="100%"
    >
      {dataInRange.sensors ? (
        dataInRange.sensors.map((sensor, index) => (
          <Pin sensor={sensor} config={dataInRange.config} index={index} key={`sensorPin${index}`} />
        ))
      ) : (
        <Error />
      )}
      <Navigation data={dataInRange} />
    </ReactMapGL>
  )
}
