import React from "react"
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts"

export default props => {
  return props.sensor.leq.length > 0 ? (
    <div>
      <div align="left" style={{ paddingLeft: "2.4em" }}>
        <label>
          Soundlevel {" "}
          <a
            className="text-secondary"
            href="https://www.castlegroup.co.uk/guidance/noise-at-work-assessments/what-is-leq/"
          >
            [Leq]
          </a>
        </label>
      </div>
      <ResponsiveContainer height={200} className="">
        <AreaChart data={props.sensor.leq} margin={props.chartMargin}>
          <CartesianGrid
            strokeDasharray="3 3"
            stroke="rgba(255, 255, 255, 0.1)"
          />
          <YAxis
            yAxisId="left"
            label={{
              angle: -45,
              position: "insideLeft",
            }}
            domain={[20, 120]}
            scale='linear'
            minTickGap={10}
            interval={0} // show all ticks
          />
          <XAxis dataKey="endTimeString" />
          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="value"
            stroke="#587d83"
            strokeOpacity={1}
            fill="#587d83"
            fillOpacity={0.5}
            yAxisId="left"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <div className="text-danger mt-4">
      Sufficient data for noise level not available.
    </div>
  )
}
