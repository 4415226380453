import React from "react"
import LogoLink from "./LogoLink"
import OverviewButton from "./OverviewButton"
import DateRangeInput from "./DateRangeInput.js"
import ButtonGroup from "react-bootstrap/ButtonGroup"

export default props => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        padding: "10px",
        width: "100%",
      }}
    >
      <div className="d-flex w-100 justify-content-between">
        {/* Left top */}
        <div>
          <LogoLink text />
        </div>
        {/* Right top */}
        <div className="rounded-pill">
          <ButtonGroup aria-label="Basic example">
            <OverviewButton data={props.data} />
            <DateRangeInput />
          </ButtonGroup>
        </div>
      </div>
    </div>
  )
}
