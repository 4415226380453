import React from "react"
import logo from "../../../images/logo.png"

export default props => (
  <a href="https://soundsensing.no/" style={{ textDecoration: "none" }}>
    <div className="d-flex align-items-center">
      <img
        src={logo}
        alt="Soundsense logo"
        style={{ height: "2.4em" }}
        className=""
      />
      {props.text && (
        <div
          style={{
            textShadow: "2px 2px 6px rgba(0, 0, 0, .35)",
            position: "relative",
            bottom: ".1em",
          }}
          className="ml-2 d-none d-sm-inline"
        >
          <div className="text-secondary mb-n2">
            <small>Noise monitoring</small>
          </div>
          <div className="text-light">Soundsensing</div>
        </div>
      )}
    </div>
  </a>
)
