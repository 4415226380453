import React from "react"
import LeqChart from "../charts/LeqChart"
import PredictionChart from "../charts/PredictionChart"

const chartMargin = { top: 0, right: 46, left: -20, bottom: 0 }

export default (props) => {
  return (
    <figure>
      <div
        className="mb-3"
        // style={{ padding: "0 4.1em" }}
        // align="left"
      >
        <h6 className="m-0">{props.sensor.description}</h6>
        <div className="text-secondary">{props.sensor.id}</div>
      </div>
      <LeqChart {...props} chartMargin={chartMargin} />
      <PredictionChart {...props} chartMargin={chartMargin} />
    </figure>
  )
}
