import cloneDeep from "lodash/cloneDeep"
import format from "date-fns/format"

export function addHoursDateNumber(time, hours) {
  return (time += hours * 3600000)
}

export function floorHour(time) {
  time.setMinutes(0, 0, 0)
  return time
}

export function roundHour(time) {
  time.setHours(time.getHours() + Math.round(time.getMinutes() / 60))
  time.setMinutes(0, 0, 0)
  return time
}

export function daysAgo(days, date = new Date(Date.now())) {
  date.setDate(date.getDate() - days)
  return date
}

export function now() {
  return new Date(Date.now())
}

// Expects bytes and returns in MB with 2 decimals.
// parseFloat so we get 10 and not 10.00
export function bytesToMB(filesize) {
  return parseFloat((filesize/1e6).toFixed(2))
}

export function roundTwoDecimals(number) {
  return parseFloat(number.toFixed(2))
}

/**
 * Converts ISO-dates to JS Date-number-format in a given dataset
 * @param {object} data Data to convert
 * @param {array} logs Logs of data to process
 * @return {object} Converted data
 */
export function dataIsoToDateNumber(data, logs = ["leq", "predictions"]) {
  data.forEach(sensor => {
    logs.forEach(log => {
      sensor[log].forEach(row => {
        // row.endTime = new Date(row.endTime)
        row.endTime = Date.parse(row.endTime)
      })
    })
  })
  return data
}

export function isEmptyObject(object) {
  return Object.keys(object).length === 0 && object.constructor === Object
}

export function updateStatus(data, logs = ["leq", "predictions"]) {
  data.forEach(sensor => {
    let activeLogs = 0
    logs.forEach(log => {
      if (sensor[log].length > 0) {
        activeLogs += 1
      }
    })
    if (activeLogs === logs.length) {
      sensor.status = "success"
    } else if (activeLogs > 0) {
      sensor.status = "warning"
    } else {
      sensor.status = "danger"
    }
  })
  return data
}

/**
 * Finds index of time matching row of a log
 * @param {string[]} log Log to search
 * @param {number} time Time to match
 * @return {number} Index of matching row
 */
export function rowTimeMatch(log, time) {
  return log.findIndex(row => row.endTime === time)
}

/**
 * Finds initial time of a data set (as number or date)
 * @param {Object[]} data Data to compress
 * @param {string[]} logs Logs of data to process, default is ["leq", "predictions"]
 * @return {number} Initial time of data
 */
export function findInitialTime(data, logs = ["leq", "predictions"]) {
  let time = Infinity
  data.forEach(sensor => {
    logs.forEach(log => {
      sensor[log].forEach(row => {
        if (row.endTime && row.endTime < time) {
          time = row.endTime
        }
      })
    })
  })
  if (time !== Infinity) {
    return time
  }
}

/**
 * Finds final time of a data set (as number or date)
 * @param {Object[]} data Data to compress
 * @param {string[]} logs Logs of data to process, default is ["leq", "predictions"]
 * @return {number} Final time of data
 */
export function findFinalTime(data, logs = ["leq", "predictions"]) {
  let time = 0
  data.forEach(sensor => {
    logs.forEach(log => {
      sensor[log].forEach(row => {
        if (row.endTime && row.endTime > time) {
          time = row.endTime
        }
      })
    })
  })
  if (time !== 0) {
    return time
  }
}

/**
 * Compresses data by a given factor
 *
 * Warning: Only works on logs with endTime in number format,
 * as string format logs will be emptied! Also needs floored hours.
 * @param {Object[]} data Data to compress
 * @param {string[]} logs What logs to include, default is ["leq", "predictions"]
 * @param {number} tI Integer, initial time of data, defaults to what is found in data
 * @param {number} tF Integer, final time of data, defaults to what is found in data
 * @return {Object[]} Compressed data
 */
export function fillGaps(
  data,
  logs = ["leq", "predictions"],
  tI = findInitialTime(data, logs),
  tF = findFinalTime(data, logs)
) {
  data.forEach(sensor => {
    logs.forEach(log => {
      if (sensor[log].length > 0) {
        let newLog = []
        let t = tI
        while (t <= tF) {
          const index = rowTimeMatch(sensor[log], t)
          if (index === -1) {
            const newRow = { endTime: t }
            newLog.push(newRow)
          } else {
            newLog.push(sensor[log][index])
          }
          t = addHoursDateNumber(t, 1)
        }
        sensor[log] = newLog
      } else {
        sensor[log] = []
      }
    })
  })
  return data
}

/**
 * Calculates Leq given an array of Leqs
 * @param {number[]} leqs Array of Leqs
 * @return {number} Leq
 */
export function leq(leqs) {
  // Temporary average calculation as placeholder
  let hasSamples = false

  for (const leq of leqs) {
    if (typeof leq === "number") {
      hasSamples = true
      break
    }
  }

  if (leqs.length > 0 && hasSamples) {
    // let sum = 0
    // leqs.forEach(leq => {
    //   if (leq === undefined) {
    //     leqs.length -= 1
    //   } else {
    //     sum += leq
    //   }
    // })

    // const average = sum / leqs.length
    // return Math.round(average)

    const sum = array => array.reduce((a, b) => a + b, 0)

    let values = []
    leqs.forEach(leq => {
      const value = 10 ** (leq / 10)
      values.push(value)
    })
    const average = sum(values) / leqs.length
    const result = Math.round(10 * Math.log10(average))
    return result
  }
}

/**
 * Returns a single row object with all values being
 * averages of all other row's values
 * @param {Object[]} data Data to average
 * @return {Object} Average row
 */
export function avgObject(object) {
  const averageArray = Array.from(
    object.reduce(
      (acc, obj) =>
        Object.keys(obj).reduce(
          (acc, key) =>
            typeof obj[key] === "number"
              ? acc.set(key, (acc.get(key) || []).concat(obj[key]))
              : acc,
          acc
        ),
      new Map()
    ),
    ([name, values]) => ({
      name,
      average: values.reduce((a, b) => a + b) / values.length,
    })
  )
  const averageObject = {}
  averageArray.forEach(entry => {
    averageObject[entry.name] = entry.average
  })
  return averageObject
}

/**
 * Compresses data by a given factor
 *
 * Warning: Requires floored/rounded hours and fillGaps to work correctly!
 *
 * TODO:
 * - Decide on endTimes for compressed rows (leq and predictions)
 * @param {Object[]} data Data to compress
 * @param {number} factor Integer, how many hours to compress into a single row, defaults to 24
 * @param {string[]} logs What logs to include, default is ["leq", "predictions"]
 * @return {Object[]} Compressed data
 */
export function compressData(data, factor = 24, logs = ["leq", "predictions"]) {
  const tI = findInitialTime(data, logs)
  const hour = 1000 * 60 * 60
  const resolution = hour * factor
  data.forEach(sensor => {
    logs.forEach(log => {
      if (log && !isEmptyObject(log)) {
        let compressedLog = []
        let tSample = tI + resolution - hour
        let leqs = []
        let predictions = []

        sensor[log].forEach(row => {
          let compressedRow = {}
          if (log === "leq" && typeof row.value === "number") {
            leqs.push(row.value)
          } else if (log === "predictions") {
            predictions.push(row)
          }

          if (row.endTime >= tSample) {
            if (log === "leq") {
              // Needs to be looked at
              compressedRow.endTime = tSample
              compressedRow.value = leq(leqs)
              leqs = []
            } else if (log === "predictions") {
              // Needs to be looked at
              compressedRow.endTime = tSample
              compressedRow = avgObject(predictions)
              predictions = []
            }

            compressedLog.push(compressedRow)
            tSample += resolution
          }
        })
        sensor[log] = compressedLog
      }
    })
  })
  return data
}

export function removeSingles(data, logs = ["leq"]) {
  data.forEach(sensor => {
    logs.forEach(log => {
      let adjacentValues = false
      let lastRowHadValue = false

      for (const row of sensor[log]) {
        const keys = Object.keys(row)
        const firstValueKey = keys[1]
        if (row[firstValueKey]) {
          if (lastRowHadValue) {
            adjacentValues = true
            break
          }
          lastRowHadValue = true
        } else {
          lastRowHadValue = false
        }
      }

      if (!adjacentValues) {
        sensor[log] = []
      }
    })
  })
  return data
}

export function addDatetimeString(
  data,
  logs = ["leq", "predictions"],
  includeTime = false
) {
  data.forEach(sensor => {
    logs.forEach(log => {
      sensor[log].forEach(row => {
        if (includeTime) {
          row.endTimeString = format(new Date(row.endTime), "dd.MM.yy HH:mm")
        } else {
          row.endTimeString = format(new Date(row.endTime), "dd.MM.yy")
        }
      })
    })
  })
  return data
}

// Move predictions from .value[...] array to .class1,2,3
function flattenPredictions(p) {
    var d = Object(p);
    var values = d['value'];
    delete d['value'];

    if (values) {
        values.map((val, idx) => {
            d['class'+idx] = val;
        });
    }

    return d;
}

/**
 * Takes a set of data and returns properly formatted
 * data in a given time range
 * @param {Object[]} data Data to convert
 * @param {Date} tI Earliest time to retrieve data from, defaults to one month ago
 * @param {Date} tF Latest time to retrieve data from, defaults to now
 * @param {string[]} logs Logs of data to process, default is ["leq", "predictions"]
 * @param {number} compression Compression factor (how many hours to squeeze into one row),
 * must be integer
 * @param {boolean} includeTime Whether to include hh:mm in timestamp strings or not
 * @return {Object[]} Converted data
 */
export function getDataInRange(
  data,
  tI = daysAgo(7 * 4),
  tF = now(),
  logs = ["leq", "predictions"],
  compression = 1,
  includeTime = false
) {
  // Floors instead of rounds,
  // as we don't have values from the future.
  // Then we are assured there will be no
  // sudden cutoff on the graphs shown.
  tI = floorHour(tI)
  tF = floorHour(tF)
  tI = Date.parse(tI)
  tF = Date.parse(tF)

  let newData = cloneDeep(data.data)
  newData = dataIsoToDateNumber(newData, logs)
  newData.forEach(sensor => {
    sensor.predictions = sensor.predictions.map(flattenPredictions);
    logs.forEach(log => {
      // Remove rows before tI
      sensor[log] = sensor[log].filter(row => row.endTime >= tI)
    })

  })
  newData = fillGaps(newData, logs, tI, tF)
  newData = compressData(newData, compression, logs)
  newData = removeSingles(newData, ["leq"])
  newData = updateStatus(newData, logs)
  newData = addDatetimeString(newData, logs, includeTime)
  const out = {
    'sensors': newData,
    'config': cloneDeep(data.config),
  }

  return out
}
