export default {
  primary: "#587d83",
  // "secondary": "#565c63",
  success: "#34c27e",
  // "info": "#17a2b8",
  // "warning": "#c9e4ca",
  warning: "#efab58",
  danger: "#de3c4b",
  // "light": "#f8f9fa",
  dark: "#23272b",
}
