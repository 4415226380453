import React, { useState, useContext } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

import { DateRange } from "react-date-range"
import "react-date-range/dist/styles.css"
import "../../../styles/date.scss"

import colors from "../../../styles/colors"
import { now, daysAgo, getDataInRange } from "../../../functions/general"
import { DataContext, data } from "../modules/DataContext"

export default props => {
  const [show, setShow] = React.useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const setDataInRange = useContext(DataContext)[1]
  const updateData = (start, stop) => {
    setDataInRange(() => {
      const newData = getDataInRange(data, start, stop)
      setDataInRange(newData)
    })
  }

  const [ranges, setRanges] = useState([
    {
      startDate: daysAgo(7 * 4),
      endDate: now(),
      key: "selection",
    },
  ])

  const handleChange = item => {
    setRanges([item.selection])
    updateData([item.selection][0].startDate, [item.selection][0].endDate)
  }

  return (
    <>
      <Button onClick={handleShow} className="shadow">
        Date range
      </Button>
      <Modal
        className="d-sm-flex"
        show={show}
        size="lg"
        onHide={handleClose}
        centered
        animation={true}
        dialogClassName="rounded"
        scrollable
      >
        <Modal.Body align="center">
          <DateRange
            className="overflow-auto"
            showMonthArrow={true}
            onChange={item => {
              handleChange(item)
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={ranges}
            direction="vertical"
            rangeColors={[colors.primary]}
            headerContent={``}
            footerContent={``}
            maxDate={new Date(Date.now())}
            showDateDisplay={true}
            weekStartsOn={1}
          />
          <div>
            <Button className="w-100" variant="primary" onClick={handleClose}>
              Back
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
