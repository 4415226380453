import React from "react"
import {
  ResponsiveContainer,
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts"

export default props => {

  const sources = props.config.classification_info.urbansound8k.classnames;

  // FIXME: use more distinct colors
  // TODO: support any number of classes
  const colors = [
    "#FFEFF0",
    "#FFD1D3",
    "#FFB3B5",
    "#FFA4A7",
    "#FF9598",
    "#FF8689",
    "#FF777B",
    "#FF686C",
    "#FF595E",
    "#E85156",
  ]

  const predictions = props.sensor.predictions;

  return predictions.length > 0 ? (
    <div className={`${props.sensor.leq.length > 1 && `mt-0`}`}>
      <div align="left" style={{ paddingLeft: "2.4em" }}>
        <div>Sources</div>
      </div>
      <ResponsiveContainer height={175} className="mt-2">
        <BarChart data={props.sensor.predictions} margin={props.chartMargin}>
          <CartesianGrid
            strokeDasharray="3 3"
            stroke="rgba(255, 255, 255, 0.1)"
          />
          <XAxis dataKey="endTimeString" />
          <YAxis />

          {Array.isArray(sources) &&
            sources.length > 0 &&
            sources.map((source, sourceIndex) => {
              return (
                <Bar
                  key={`class${sourceIndex}Key`}
                  dataKey={`class${sourceIndex}`}
                  stackId="a"
                  stroke={colors[sourceIndex]}
                  fill={colors[sourceIndex]}
                  isAnimationActive={false}
                />
              )
            })}
        </BarChart>
      </ResponsiveContainer>
      <div className="w-100 d-flex justify-content-between px-5 mt-3">
        {Array.isArray(sources) &&
          sources.length > 0 &&
          sources.map((source, sourceIndex) => {
            return (
              <div>
                <b
                  style={{
                    color: colors[sourceIndex],
                  }}
                  className="d-inline"
                >
                  {source}
                </b>
              </div>
            )
          })}
      </div>
    </div>
  ) : (
    <div className="text-danger mt-4">
      Sufficient data for classifications not available.
    </div>
  )
}
