import React, { createContext, useState } from "react"
import {
  getDataInRange,
  // now,
  // daysAgo
} from "../../../functions/general"

//import someData from "../../../../ssg-import/data.json"

let someData = {}

try {
  someData = require("../../../../ssg-import/data.json")
} catch (error) {
  console.log(error)
}

export const data = someData

export const DataContext = createContext()

export const DataProvider = props => {
  const [dataInRange, setDataInRange] = useState(getDataInRange(data))
  return (
    <DataContext.Provider value={[dataInRange, setDataInRange]}>
      {props.children}
    </DataContext.Provider>
  )
}
