import React, { useState } from "react"
import { Marker } from "react-map-gl"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import SensorData from "./SensorData"

/**
 * Based on example:
 * https://uber.github.io/react-map-gl/examples/controls
 *
 * Rewritten to not use class
 */

function getStatus(sensor) {
    const hasLeq = sensor.leq.length != 0
    const hasPreds = sensor.predictions.length != 0;

    if (hasLeq && hasPreds) {
        return "success";
    } else if (hasLeq || hasPreds) {
        return "warning";
    } else {
        return "danger";
    }
}

// Important for perf: the markers never change, avoid rerender when the map viewport changes
export default props => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const status = getStatus(props.sensor);

  // Status Icon-style
  const sensorStatusStyle = status => {
    if (status === "success") {
      return "sensor-dot-success"
    } else if (status === "warning") {
      return "sensor-dot-warning"
    } else {
      return "sensor-dot-danger"
    }
  }

  return (
    <div key={`marker${props.index}`}>
      <Marker
        latitude={props.sensor.position[0]}
        longitude={props.sensor.position[1]}
        captureDoubleClick={false}
        captureDrag={false}
      >
        <Button onClick={handleShow} variant="" className="px-0 py-2 m-0">
          <div className={`sensor-dot ${sensorStatusStyle(status)} unfocus`}>
            {props.sensor.description}
          </div>
        </Button>
      </Marker>
      <Modal
        className="my-modal text-light"
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        animation={true}
        dialogClassName="rounded"
        scrollable
      >
        <Modal.Body align="center">
          <SensorData
            sensor={props.sensor}
            index={props.index}
            config={props.config}
          />
          <div className="mt-3 pt-3">
            <Button className="w-100" variant="primary" onClick={handleClose}>
              Back to map
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
