import React from "react"
import Dashboard from "../components/ssg/Dashboard"
import { DataProvider } from "../components/ssg/modules/DataContext"
import "../styles/styles.css"
import "../styles/bootstrap.scss"
import Div100vh from "react-div-100vh"

export default () => {
  return (
    <DataProvider>
      <Div100vh>
        <Dashboard />
      </Div100vh>
    </DataProvider>
  )
}
