import React from "react"
import SensorData from "../modules/SensorData"
import Button from "react-bootstrap/Button"

export default props => {
  return (
    <>
      {props.data.sensors.map((sensor, index) => (
        <div key={`sensorDataOverview${index}`}>
          <SensorData config={props.data.config} sensor={sensor} index={index} />
          {index !== props.data.length - 1 && (
            <hr
              className="w-100 my-5"
              style={{ backgroundColor: "rgba(255, 255, 255, 0.15" }}
            />
          )}
        </div>
      ))}
      <div className="mt-3 pt-3">
        <Button className="w-100" variant="primary" onClick={props.onClose}>
          Back to map
        </Button>
      </div>
    </>
  )
}
